import React from 'react'
import Header from '../../component/Header'
import AboutUs from '../AboutUs'
import Footer from '../../component/Footer'
import CourseMBA from '../CourseMBA'

export default function LayoutCourseMBA() {
    return (
        <div>
            <Header />
            <CourseMBA />
            <Footer />
        </div>
    )
}
