import React from 'react'
import Header from '../../component/Header'
import Footer from '../../component/Footer'
import Home from '../Home'
import PhotoGallery from '../PhotoGallery'

export default function LayoutGallery() {
  return (
    <>
      <Header />
      <PhotoGallery />
      <Footer />
    </>
  )
}
