import React from 'react'
import Header from '../../component/Header'
import Footer from '../../component/Footer'
import Undergraduate from '../Undergraduate'

export default function LayoutUndergraduate() {
    return (
        <div>
            <Header />
            <Undergraduate />
            <Footer />
        </div>
    )
}
