import React from 'react'
import Header from '../../component/Header'
import Footer from '../../component/Footer'
import Dac from '../Dac'
import Nielit from '../Nielit'

export default function LayoutNielit() {
    return (
        <div>
            <Header />
            <Nielit />
            <Footer />
        </div>
    )
}
